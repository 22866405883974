<template>
  <div >
    <b-row>
      <b-col>
        <UsersTableWidget
          :title="'Customers'"
        ></UsersTableWidget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import UsersTableWidget from "@/view/pages/business/components/UsersTableWidget.vue";
import { BUSINESS_USERS } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "Users",
  computed: {
    ...mapGetters(["currentUser" , "getDate"])
  },
  components: {
    UsersTableWidget
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Customers" },
      { title: "All Customers" }
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      // this.fetchOrders();
    }
  },

  data() {
    return {
      form: {
        users: ""
      }
    };
  },
  methods: {
    // fetchOrders() {

    //   this.$store
    //     .dispatch(BUSINESS_USERS)
    //     // go to which page after successfully login
    //     .then(resp => {
    //       this.form.users = resp.data;
    //       // this.$router.push({ name: "dashboard" })
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // }
  }
};
</script>
