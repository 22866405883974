<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark font-poppins">{{ title }} ({{ totalItems }})</span>
      </h3>
    </div>
    <div class="card-body pt-0 pb-3">
      <v-data-table
        :headers="headers"
        :items="users.data"
        :items-per-page="options.itemsPerPage"
        :server-items-length="totalItems"
        :options.sync="options"
        @update:options="handleOptionsUpdate"
        class="elevation-1"
      >
        <template v-slot:body="props">
          <tbody>
            <tr v-for="item in props.items" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.contact ? item.contact.phone_number : '' }}</td>
              <td>{{ item.orders }}</td>
              <td>{{ $store.state.auth.currency }}{{ item.amount }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <!-- Pagination Component -->
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        @input="fetchOrders"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { BUSINESS_USERS } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

export default {
  name: "UsersTableWidget",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      users: { data: [] },
    options: {
      page: 1,
      itemsPerPage: 10
    },
    totalItems: 0, // Set a default value for total items
    currentPage: 1,
    totalPages: 0,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Contact', value: 'contact' },
        { text: 'Total Orders', value: 'orders' },
        { text: 'Orders Amount', value: 'amount' }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  mounted() {
    if (this.currentUser.token !== "") {
      this.fetchOrders();
    }
  },
  methods: {
    fetchOrders() {
      const page = this.currentPage || 1;
      const itemsPerPage = this.options.itemsPerPage || 10;
      
      this.$store.dispatch(BUSINESS_USERS, { page, perPage: itemsPerPage })
        .then(resp => {
          this.users = resp;
           this.totalItems = resp.meta.total;
        this.totalPages = resp.meta.last_page;
        })
        .catch(error => {
          console.error(error);
        });
    },
    handleOptionsUpdate(options) {
      this.options.page = options.page;
      this.options.itemsPerPage = options.itemsPerPage;
      this.fetchOrders();
    }
  }
};
</script>
